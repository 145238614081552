import React from "react"
import { Modal } from "react-bootstrap"
import step1_image from "./user_guide/step1.jpg"
import step2_image from "./user_guide/step2.jpg"
import step3_image from "./user_guide/step3.jpg"
import step4_image from "./user_guide/step4.jpg"
import delete_image from "./user_guide/delete.jpg"

const UserGuide = ({ pageShowing, closePage }) => (
  <Modal show={pageShowing} onHide={closePage} dialogClassName="modals">
    <Modal.Body>
      <h3>How to do an analysis</h3>
      <br />
      <p>
        <strong>Step 1</strong>
      </p>
      <p>Zoom to your location by scrolling or using the zoom buttons in the lower right corner.</p>
      <img className="img-fluid user-guide-image" src={step1_image} alt="An aerial view of the roof of a house" />
      <p>
        <strong>Step 2</strong>
      </p>
      <p>Left click on the map to start drawing. This will begin creating a line.</p>
      <img
        className="img-fluid user-guide-image"
        src={step2_image}
        alt="An aerial view of a house with a small dot on the corner of the roof"
      />
      <p>
        <strong>Step 3</strong>
      </p>
      <p>Left click again to start expanding a rectangle from the line.</p>
      <img
        className="img-fluid user-guide-image"
        src={step3_image}
        alt="An aerial view of a house with a rectangle covering a part of the roof"
      />
      <p>
        <strong>Step 4</strong>
      </p>
      <p>
        Left click a last time to finish the rectangle. You are now ready to press the "Analyze" button, or continue
        drawing more rectangles to include in your analysis.
      </p>
      <img
        className="img-fluid user-guide-image"
        src={step4_image}
        alt="An aerial view of a house with a rectangle covering all of the roof"
      />
      <p>
        <strong>How to delete a rectangle?</strong>
      </p>
      <p>
        Left click on a rectangle and a small cross will appear in a corner. Left click on the cross to delete the
        rectangle.
      </p>
      <img
        className="img-fluid user-guide-image"
        src={delete_image}
        alt="An aerial view of a house with a rectangle covering the roof. The rectangle has a cross in one of the corners."
      />
    </Modal.Body>
  </Modal>
)

export default UserGuide
