import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import ReactMarkdown from "react-markdown"

export default class MarkdownPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      markdown: "",
      showing: false,
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle() {
    console.log("toggle")
    this.setState({ showing: !this.state.showing })
  }

  componentDidMount() {
    const markdownFile = this.props.file

    fetch(markdownFile)
      .then((response) => {
        return response.text()
      })
      .then((text) => {
        this.setState({
          markdown: text,
        })
      })
  }

  render() {
    return (
      <>
        <button className="markdownButton" onClick={this.toggle}>
          {this.props.title}
        </button>
        <Modal show={this.state.showing} onHide={this.toggle} size={"s"} dialogClassName="docs">
          <Modal.Header closeButton style={{ maxHeight: "60px" }}>
            <Modal.Title style={{ fontSize: "30px", margin: "0% 5% 0% 0%", textAlign: "left", maxHeight: "20px" }}>
              {this.props.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }} closeButton>
            <ReactMarkdown source={this.state.markdown} />
          </Modal.Body>
        </Modal>
      </>
    )
  }
}
