import React, { Component, Fragment } from "react"
import { QueryDetailsContext } from "./contexts"
import { MapPanel } from "./MapPanel"
import "./style.css"
import { GoogleApiWrapper, Marker } from "google-maps-react"
import "bootstrap/dist/css/bootstrap.min.css"
import { ButtonPanel } from "./ButtonPanel"
import UserGuide from "./UserGuide"
import { ResultModal } from "./ResultModal"
import { StartModal } from "./StartModal"
import { Beforeunload } from "react-beforeunload"
import { Menu } from "./Menu"
import { ThankYouModal } from "./ThankYouModal"
import getPvOut from "./services/pvOut"
import { toSimplePolygon } from "./utils"
import MouseLabel from "./MouseLabel"

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

function angle(heading1, heading2) {
  let deg = Math.abs(heading1 - heading2)
  let rad = (deg / 360) * 2 * Math.PI
  return rad
}

function getPriceEstimate(config, units) {
  const { unitCosts, currency } = config.cost
  const unitCost = unitCosts.find(({ from, to }) => units >= from && units <= (to || Infinity)).cost
  const price = units * unitCost
  return { price, currency }
}

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currCoords: [],
      currDrawCoords: [],
      drawMarker: null,
      areas: [],
      area: null,
      numUnits: null,
      drawLength: null,
      polygonCenter: null,
      mapCenter: { lat: 50, lng: 15 },
      mapBounds: null,
      elevationData: null,
      pvOutputData: null,
      windData: null,
      waveData: null,
      tempData: null,
      priceEstimate: null,
      analysisStopped: false,
      analysisShowing: false,
      showUserGuide: false,
      zoom: 5,
      mapMessage: "",
      analysable: false,
      analysisReady: false,
      showStartModal: true,
      analysisSubmitted: false,
      cursor: "crosshair",
      polygonBounds: null,
      thankyoumessage: false,
      mouseOverPolygon: false,
      locationMarker: null,
    }
    this.mapClick = this.mapClick.bind(this)
    this.handleTrack = this.handleTrack.bind(this)
    this.resetButtonClick = this.resetButtonClick.bind(this)
    this.resetOutput = this.resetOutput.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown)
    let initAreas = this.parseInitAreas(this.props.areas)
    if (initAreas.length > 0) {
      let initBounds = this.getBoundsFromCoords(initAreas.flat())
      let initPolygonCenter = initBounds.getCenter()
      let [areadefined, areaok, numunitsok] = this.updateAreaParamState(initAreas)
      if (areaok && numunitsok) {
        this.setState({ analysable: true })
      } else if (areadefined && numunitsok) {
        this.sendMapMessage("Reset and draw a smaller area.")
        this.resetOutput()
      }

      this.setState(
        { areas: initAreas, mapBounds: initBounds, polygonCenter: initPolygonCenter, showStartModal: false },
        () => {
          this.analysisToggle(false)
        }
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown)
  }

  onKeyDown(e) {
    if (e.key === "Escape") {
      this.setState({ currCoords: [], currDrawCoords: [], drawMarker: null })
    }
  }

  parseInitAreas = (areas) => {
    if (areas) {
      let res = []
      areas.split(" ").forEach((coordinates) => {
        let area = []
        coordinates.split(";").forEach((pair) => {
          let [lat, lng] = pair.split(",").map(Number)
          area.push(new this.props.google.maps.LatLng(lat, lng))
        })
        res.push(area)
      })
      return res
    } else {
      return []
    }
  }

  evaluateCenter = (polygonCenter) => {
    return polygonCenter
      ? {
          lat: polygonCenter.lat(),
          lng: polygonCenter.lng(),
        }
      : { lat: 0, lng: 0 }
  }

  changeMapCenter = (newCenter, newBounds) => {
    this.resetButtonClick()
    this.state.showStartModal
      ? this.setState({
          showStartModal: false,
          mapCenter: newCenter,
          mapBounds: newBounds,
        })
      : this.setState({
          mapCenter: newCenter,
          mapBounds: newBounds,
        })
  }

  setLocationMarker = (locationMarker) => {
    this.setState({ locationMarker })
  }

  toastClose = () => {
    this.setState({ mapMessage: "" })
    this.resetButtonClick()
  }

  getBoundsFromCoords = (coords) => {
    let bounds = new this.props.google.maps.LatLngBounds()
    coords.forEach(function (item, index) {
      bounds.extend(item)
    })
    return bounds
  }

  fetchData = async () => {
    let lat = this.state.polygonCenter.lat()
    let lng = this.state.polygonCenter.lng()
    let units = this.state.numUnits

    const pvOutData = await getPvOut(lat, lng, this.state.areas.map(toSimplePolygon), this.props.config)
    const priceEstimate = getPriceEstimate(this.props.config, units)

    if (!this.state.analysisStopped) {
      this.setState({ analysisReady: true, priceEstimate, pvOutputData: pvOutData })
    }
  }

  computeNumUnits = (rect) => {
    const mps = this.props.google.maps
    const sphere = mps.geometry.spherical
    if (rect.length === 4) {
      let rectWidth = sphere.computeDistanceBetween(rect[0], rect[1])
      let rectHeight = sphere.computeDistanceBetween(rect[0], rect[3])

      const { width, height } = this.props.config.unit
      let k = Math.floor(rectWidth / width)
      let s = Math.floor(rectWidth / height)
      let q = Math.floor(rectHeight / width)
      let u = Math.floor(rectHeight / height)

      return Math.max(k * u, s * q)
    } else {
      return 0
    }
  }
  toRect = (coords, scale) => {
    let mps = this.props.google.maps
    let sphere = mps.geometry.spherical
    if (coords.length > 1) {
      let L = coords.length - 1
      let x = new mps.LatLng({ lat: coords[L].lat(), lng: coords[L].lng() })
      let orig = new mps.LatLng({ lat: coords[0].lat(), lng: coords[0].lng() })
      let htrack = sphere.computeHeading(orig, x)
      let radius = sphere.computeDistanceBetween(orig, x)

      if (coords.length > 2) {
        let p = new mps.LatLng({ lat: coords[1].lat(), lng: coords[1].lng() })
        let h1 = sphere.computeHeading(orig, p)
        let newh = h1 > htrack ? h1 - 90 : h1 + 90
        let theta = angle(h1, htrack)
        let d = Math.sin(theta) * radius
        let tp0 = sphere.computeOffset(p, scale * d, newh)
        let tp1 = sphere.computeOffset(orig, scale * d, newh)
        return [tp0, tp1]
      } else {
        let tp = sphere.computeOffset(orig, scale * radius, htrack)
        return [tp]
      }
    }
  }
  deleteArea = (index) => {
    let areas = [...this.state.areas]
    areas.splice(index, 1)
    this.updateAreaParamState(areas)
    if (areas.length === 0) {
      this.setState({ analysable: false })
    }
    this.setState({ areas })
  }

  updateAreaParamState = (areas) => {
    if (areas.length === 0) {
      this.setState({
        area: null,
        numUnits: null,
      })
      return [false, false, false]
    }
    let mps = this.props.google.maps
    let sphere = mps.geometry.spherical
    let allAreas = areas.map((coords) => sphere.computeArea(coords))

    let newArea = allAreas.reduce((a, b) => a + b, 0)

    let allUnits = areas.map((coords) => this.computeNumUnits(coords))

    let newNumUnits = allUnits.reduce((a, b) => a + b, 0)

    if (newArea && newArea < 5e6 && newNumUnits <= 0) {
      this.setState({
        area: newArea,
        numUnits: newNumUnits,
      })
      return [true, true, false]
    } else if (newArea && newArea < 5e6 && newNumUnits > 0) {
      this.setState({
        area: newArea,
        numUnits: newNumUnits,
      })
      return [true, true, true]
    } else if (newArea) {
      return [true, false, false]
    } else {
      return [false, false, false]
    }
  }

  handleTrack(t, map, coord) {
    let mps = this.props.google.maps
    let sphere = mps.geometry.spherical
    let tempCoords = this.toRect([...this.state.currCoords, coord.latLng], 0.99)
    if (tempCoords) {
      let newDrawCoords = [...this.state.currCoords, ...tempCoords]
      if (newDrawCoords.length === 4) {
        let allAreas = [...this.state.areas, newDrawCoords]
        let [areadefined, areaok] = this.updateAreaParamState(allAreas)
        if (areadefined && !areaok) {
          this.sendMapMessage("Reset and draw a smaller area.")
          this.resetOutput()
        }
        this.setState({
          currDrawCoords: newDrawCoords,
          drawLength: null,
        })
      } else if (newDrawCoords.length === 2) {
        let drawLength = sphere.computeDistanceBetween(newDrawCoords[0], newDrawCoords[1])
        this.setState({
          drawLength: drawLength,
          currDrawCoords: newDrawCoords,
        })
      }
    }
  }

  mapClick(t, map, coord) {
    this.setState({
      analysisStopped: false,
    })
    let newCoordinates = [...this.state.currCoords, coord.latLng]
    if (this.state.currCoords.length > 1) {
      let tempCoords = this.toRect(newCoordinates, 0.99)
      newCoordinates = [...this.state.currCoords, ...tempCoords]
    }

    let [areadefined, areaok, numunitsok] = this.updateAreaParamState([...this.state.areas, newCoordinates])
    let allCoordinates = [...this.state.areas, newCoordinates].flat()
    let newPolygonBounds = this.getBoundsFromCoords(allCoordinates)
    let newCenter = this.getBoundsFromCoords(allCoordinates).getCenter()
    if (areaok && numunitsok) {
      this.setState({ analysable: true })
    } else if (areadefined && numunitsok) {
      this.sendMapMessage("Reset and draw a smaller area.")
      this.resetOutput()
    }

    if (this.state.currCoords.length > 1) {
      let newAreas = [...this.state.areas, newCoordinates]
      this.setState({
        currCoords: [],
        currDrawCoords: [],
        drawMarker: null,
        areas: newAreas,
        polygonCenter: newCenter,
        analysisSubmitted: false,
        polygonBounds: newPolygonBounds,
      })
    } else {
      let marker =
        this.state.currCoords.length === 0 ? (
          <Marker
            position={coord.latLng}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              fillOpacity: 1,
              strokeColor: "#04fffc",
              fillColor: "#04fffc",
              strokeWeight: 5,
              scale: 5,
            }}
          />
        ) : null

      this.setState({
        drawMarker: marker,
        currCoords: newCoordinates,
        polygonCenter: newCenter,
        analysisSubmitted: false,
        polygonBounds: newPolygonBounds,
      })
    }
  }

  resetButtonClick() {
    this.setState({
      currCoords: [],
      currDrawCoords: [],
      areas: [],
      area: null,
      drawMarker: null,
      numUnits: null,
      drawLength: null,
      polygonCenter: null,
      elevationData: null,
      pvOutputData: null,
      windData: null,
      waveData: null,
      tempData: null,
      priceEstimate: null,
      analysisStopped: true,
      mapMessage: "",
      analysable: false,
      analysisReady: false,
      polygonBounds: null,
    })
  }

  resetOutput() {
    this.setState({
      area: null,
      numUnits: null,
      drawLength: null,
      drawMarker: null,
      currCoords: [],
      polygonCenter: null,
      pvOutputData: null,
      windData: null,
      waveData: null,
      tempData: null,
      priceEstimate: null,
      analysisReady: false,
      analysable: false,
    })
  }

  analysisToggle = (submitButtonClicked) => {
    if (this.state.analysisShowing) {
      if (!this.state.analysisSubmitted && this.state.analysisReady) {
        this.setState({ analysisSubmitted: true })
      }
      if (submitButtonClicked) {
        this.toggleThankYouModal()
      }
    } else {
      this.fetchData()
    }
    this.setState({ analysisShowing: !this.state.analysisShowing })
  }

  sendMapMessage = (msg) => {
    this.setState({
      mapMessage: msg,
    })
  }
  toggleStartModal = () => {
    this.setState({ showStartModal: false })
  }
  toggleThankYouModal = () => {
    this.setState({ thankyoumessage: !this.state.thankyoumessage })
  }
  drawToggle = () => {
    this.state.cursor === "crosshair" ? this.setState({ cursor: "" }) : this.setState({ cursor: "crosshair" })
  }
  gotoCurrentLoc = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        this.setState({
          showStartModal: false,
          mapCenter: pos,
          zoom: 17,
        })
      })
    } else {
      console.log("Geolocation is not supported by this browser.")
    }
  }
  updateZoom = (e, map) => {
    //x.setTilt(0)
    this.setState({
      zoom: map.zoom,
    })
  }

  setMouseOverPolygon = (mouseOverPolygon) => this.setState({ mouseOverPolygon })

  render() {
    return (
      <Fragment>
        <StartModal
          pageShowing={this.state.showStartModal}
          pageToggle={this.toggleStartModal}
          mapCenter={this.state.mapCenter}
          changeCenter={this.changeMapCenter}
          myLoc={this.gotoCurrentLoc}
          setLocationMarker={this.setLocationMarker}
        />
        <Menu
          analysable={this.state.analysable}
          analysisToggle={this.analysisToggle}
          changeCenter={this.changeMapCenter}
          center={this.state.mapCenter}
          area={this.state.area}
          length={this.state.drawLength}
          numUnits={this.state.numUnits}
        />
        <ButtonPanel showUserGuide={() => this.setState({ showUserGuide: true })} />
        <UserGuide
          pageShowing={this.state.showUserGuide}
          closePage={() => this.setState({ showUserGuide: false })}
          logo={this.props.logo}
        />
        <ResultModal
          pageShowing={this.state.analysisShowing}
          pageToggle={this.analysisToggle}
          title={"Analysis results"}
          pvOutputData={this.state.pvOutputData}
          priceEstimate={this.state.priceEstimate}
          coordinates={this.state.areas.flat()}
          areas={this.state.areas}
          area={this.state.area}
          numUnits={this.state.numUnits}
          google={this.props.google}
          mapCenter={this.evaluateCenter(this.state.polygonCenter)}
          polygonBounds={this.state.polygonBounds}
          zoom={this.state.zoom}
          analysisReady={this.state.analysisReady}
          logo={this.props.logo}
          config={this.props.config}
        />
        <ThankYouModal
          pageShowing={this.state.thankyoumessage}
          pageToggle={this.toggleThankYouModal}
          logo={this.props.logo}
          logo_url={this.props.logo_url}
        />
        <MapPanel
          google={this.props.google}
          onClick={this.mapClick}
          handleTrack={this.handleTrack}
          drawMarker={this.state.drawMarker}
          currDrawCoords={this.state.currDrawCoords}
          zoomLevel={this.state.zoom}
          mapMessage={this.state.mapMessage}
          toastClose={this.toastClose}
          mapCenter={this.state.mapCenter}
          mapBounds={this.state.mapBounds}
          changeCenter={this.changeMapCenter}
          cursor={this.state.cursor}
          zoomEvent={this.updateZoom}
          areas={this.state.areas}
          config={this.props.config}
          deleteArea={this.deleteArea}
          setMouseOverPolygon={this.setMouseOverPolygon}
          locationMarker={this.state.locationMarker}
        />
        {!this.state.showStartModal && !this.state.analysisShowing && (
          <MouseLabel currCoords={this.state.currCoords} mouseOverPolygon={this.state.mouseOverPolygon} />
        )}
        <Beforeunload onBeforeunload={this.resetButtonClick} />
      </Fragment>
    )
  }
}
Dashboard.contextType = QueryDetailsContext
export default GoogleApiWrapper({
  apiKey: API_KEY,
  libraries: ["geometry", "places"],
})(Dashboard)
