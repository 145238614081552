import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import FirebaseProvider from "./FirebaseProvider"
const Admin = React.lazy(() => import("./Admin/Admin"))

const Content = () => (
  <Suspense fallback={null}>
    <Router>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route component={App} />
      </Switch>
    </Router>
  </Suspense>
)

ReactDOM.render(
  <React.StrictMode>{React.createElement(FirebaseProvider(Content))}</React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
