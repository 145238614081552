import React from "react"
import { Modal } from "react-bootstrap"
import { Search } from "./MapSearch"

export function StartModal(props) {
  return (
    <>
      <Modal
        show={props.pageShowing}
        onHide={props.pageToggle}
        dialogClassName="start-modal"
        id={"start-modal"}
        centered
        backdrop="static"
        enforceFocus={false}
        size={"xl"}
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <h2>Where do you want your installation?</h2>
          <div className="center-column">
            <Search
              changeCenter={props.changeCenter}
              center={props.mapCenter}
              setLocationMarker={props.setLocationMarker}
            />
            <p> or </p>
            <button className={"button"} id={"myloc"} onClick={props.myLoc}>
              Go to my location
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
