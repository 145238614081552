import React from "react"
import { Modal } from "react-bootstrap"
export function ThankYouModal(props) {
  //  const [showing, setShowing] = useState(true);
  return (
    <>
      <Modal
        show={props.pageShowing}
        onHide={props.pageToggle}
        dialogClassName="thank-you"
        id={"thank-you-modal"}
        size={"s"}
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <h2 style={{ marginBottom: "30px", marginTop: "60px" }}>Thank You!</h2>
          <p style={{ marginBottom: "60px", marginTop: "30px" }}>
            Thank you for your interest, you will hear from us shortly.
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}
