import React from "react"
import { getPowerUnit } from "./Backend"

export const format_number = (num) => {
  if (isNaN(num)) {
    return num
  }
  if (num % 1 === 0) {
    return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
  } else {
    return String(num.toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
  }
}

export function DataField(props) {
  return (
    <div className="databox">
      <p className="numberheading"> {props.heading}</p>
      <p className="largenumber"> {props.datafield}</p>
    </div>
  )
}
export function MainDataField(props) {
  return (
    <>
      <p className="numberheading"> {props.heading}</p>
      <p className="largenumber"> {props.datafield}</p>
    </>
  )
}

export function DataCol(props) {
  const pp = props.pvOutputData["peak_power"]
  const av = props.pvOutputData["annual_variability"]
  const peakUnits = getPowerUnit(pp)
  const annualVarUnits = getPowerUnit(av)
  const ppfield = <> {format_number(pp / peakUnits.divider) + " " + peakUnits.unit}Wp</>
  const avfield = <> {format_number(av / annualVarUnits.divider) + " " + annualVarUnits.unit}Wh</>
  const areafield = (
    <>
      {" "}
      {format_number(props.area)} m<sup>2</sup>
    </>
  )

  return (
    <React.Fragment>
      <DataField heading={"Installed Capacity"} datafield={ppfield} />
      <DataField heading={"Annual Variability"} datafield={avfield} />
      <DataField heading={"Area"} datafield={areafield} />
    </React.Fragment>
  )
}

export function DataBand(props) {
  const prod = props.pvOutputData["annual_energy_mean"]
  const annualProdUnits = getPowerUnit(prod)
  const prodfield = `${format_number(prod / annualProdUnits.divider)} ${annualProdUnits.unit}Wh`
  return (
    <React.Fragment>
      <div className="row" id={"analysis-output"}>
        <div className="blue-band">
            <MainDataField heading={"Annual production"} datafield={prodfield} />
        </div>
      </div>
    </React.Fragment>
  )
}
