import React, { Fragment, useMemo, useState } from "react"
import { Map, Marker, Polygon } from "google-maps-react"
import { Toast } from "react-bootstrap"
import { computeUnits, toSimplePolygon } from "./utils"

export function PolygonUnits({ coords, config, ...rest }) {
  const polygon = toSimplePolygon(coords)
  const units = computeUnits(polygon, config)
  return (
    <>
      {units.map((coords, i) => (
        <Polygon
          key={i}
          paths={coords.map(([lat, lng]) => ({ lat, lng }))}
          fillOpacity={0.7}
          fillColor="black"
          strokeColor="white"
          strokeWeight={1}
          clickable={false}
          {...rest}
        />
      ))}
    </>
  )
}

export function MapPanel(props) {
  const { areas, config, deleteArea, setMouseOverPolygon } = props
  const [deleteMarker, setDeleteMarker] = useState(null)
  const deleteWrappers = useMemo(
    () =>
      areas.map((coords, index) => (
        <Polygon
          key={index}
          paths={coords}
          fillOpacity={0}
          strokeWeight={0}
          onMouseover={() => {
            setDeleteMarker(
              <Marker
                position={coords[0]}
                onClick={() => {
                  deleteArea(index)
                  setMouseOverPolygon(false)
                  setDeleteMarker(null)
                }}
                title="Delete"
                name="DeleteMarker"
                icon={{
                  path: "M-3,-3 L3,3 M3,-3 L-3,3 M-6,0a6,6 0 1,0 12,0a6,6 0 1,0 -12,0",
                  fillOpacity: 1,
                  strokeColor: "black",
                  fillColor: "white",
                  strokeWeight: 2,
                  scale: 2,
                }}
              />
            )
            setMouseOverPolygon(true)
          }}
          onMouseout={() => {
            setDeleteMarker(null)
            setMouseOverPolygon(false)
          }}
        />
      )),
    [areas, deleteArea, setMouseOverPolygon]
  )

  const units = useMemo(
    () => areas.map((coords, index) => <PolygonUnits key={index} coords={coords} config={config} />),
    [areas, config]
  )
  return (
    <Fragment>
      <Map
        google={props.google}
        style={{ position: "relative", width: "100%", height: "100%" }}
        className={"gmap"}
        visible={true}
        onClick={props.onClick}
        onZoomChanged={props.zoomEvent}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        mapType={"hybrid"}
        tilt={0}
        scaleControl={true}
        center={props.mapCenter}
        initialCenter={props.mapCenter}
        zoom={props.zoomLevel}
        bounds={props.mapBounds}
        draggableCursor={"crosshair"}
        onMousemove={props.handleTrack}
        disableDoubleClickZoom={true}
      >
        {props.locationMarker && <Marker position={props.locationMarker} />}
        <Polygon
          paths={props.currDrawCoords}
          clickable={false}
          onMouseover={props.handleTrack}
          fillColor={props.mapMessage === "" ? "#04fffc" : "#ff3729"}
          strokeColor={props.mapMessage === "" ? "#00000080" : "#ff3729"}
        />
        {units}
        {deleteWrappers}
        {deleteMarker}
        {props.drawMarker}
      </Map>

      <Toast
        onClose={props.toastClose}
        show={props.mapMessage !== ""}
        delay={5000}
        autohide
        style={{
          position: "absolute",
          top: "45%",
          right: "45%",
          fontSize: 24,
        }}
      >
        <Toast.Header>
          <strong className="mr-auto">Area too large</strong>
        </Toast.Header>
        <Toast.Body>{props.mapMessage}</Toast.Body>
      </Toast>
    </Fragment>
  )
}
