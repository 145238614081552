import React from "react"
import { format_number } from "./OutputFields"

function MenuAddOn(props) {
  return <div className="menuaddon">{props.children}</div>
}

export function Menu(props) {
  return (
    <>
      <div className="menu">
        {props.area ? (
          <>
            <MenuAddOn key={2}>
              <div className="databoxleft">
                <p className="numberheading">Area</p>
                <p className="largenumber">
                  {" "}
                  {format_number(props.area)} m<sup>2</sup>
                </p>
              </div>
              <div className="databoxright">
                <p className="numberheading">SMARTSOLARBOX</p>
                <p className="largenumber"> {format_number(props.numUnits)}</p>
              </div>
            </MenuAddOn>
          </>
        ) : props.length ? (
          <>
            <hr />
            <MenuAddOn key={2}>
              <div className="databox">
                <p className="numberheading">Length</p>
                <p className="largenumber"> {format_number(props.length)} m</p>
              </div>
            </MenuAddOn>
          </>
        ) : (
          <></>
        )}
        {props.analysable ? (
          <MenuAddOn key={3}>
            <button className={"button"} id={"analyse-button"} onClick={props.analysisToggle}>
              Analyze
            </button>
          </MenuAddOn>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
