import React, { useEffect, useState } from "react"

export default function MouseLabel({ currCoords, mouseOverPolygon }) {
  const [mousePosition, setMousePosition] = useState()
  useEffect(() => {
    const handler = (e) => setMousePosition({ x: e.clientX, y: e.clientY })
    window.addEventListener("mousemove", handler)
    return () => window.removeEventListener("mousemove", handler)
  }, [])
  if (!mousePosition) {
    return null
  }
  let text
  if (mouseOverPolygon) {
    text = "Click 'X' to delete"
  } else if (currCoords.length === 0) {
    text = "Click to start drawing"
  } else {
    text = "Click to continue drawing"
  }
  return (
    <p
      style={{
        position: "fixed",
        left: mousePosition.x + 10,
        top: mousePosition.y - 10,
        background: "rgba(0, 0, 0, 0.5)",
        color: "white",
        padding: "3px 5px",
        borderRadius: 3,
        fontSize: 12,
      }}
    >
      {text}
    </p>
  )
}
