import React, { Component } from "react"
import { Form } from "react-bootstrap"
import { QueryDetailsContext } from "./contexts"
import { api_endpoint, authenticate } from "./Backend"

export default class SignUpPopOver extends Component {
  constructor(props) {
    super(props)

    this.state = {
      validated: false,
      name: { value: "", isValid: false, feedback: "Please fill in your name (2+ characters)" },
      company: { value: "", isValid: true, feedback: "Optional" },
      phone: { value: "", isValid: true, feedback: "Not a valid phone number. Phone number is optional" },
      email: { value: "", isValid: false, feedback: "Please fill in valid email" },
      checked: false,
      submitting: false,
      error: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.validatePhone = this.validatePhone.bind(this)
    this.validateString = this.validateString.bind(this)
    this.allValidated = this.allValidated.bind(this)
  }
  static contextType = QueryDetailsContext
  componentDidMount() {
    if (this.context.tpUserName) {
      this.validateString("name", this.context.tpUserName)
    }
    if (this.context.tpUserEmail) {
      this.validateEmail(this.context.tpUserEmail)
    }
  }
  resetForm = () => {
    this.setState({
      validated: false,
      name: { value: "", isValid: false, feedback: "Please fill in your name (2+ characters)" },
      phone: { value: "", isValid: true, feedback: "Not a valid phone number. Phone number is optional" },
      company: { value: "", isValid: true, feedback: "Optional" },
      email: { value: "", isValid: false, feedback: "Please fill in valid email" },
      checked: false,
      submitting: false,
      error: false,
    })
  }

  validateString = (stateName, stateValue) => {
    if (stateName !== undefined) {
      //If optinal and empty
      if (stateName === "company" && stateValue === "") {
        let is_valid = true
        this.setState({ [stateName]: { ...this.state[stateName], isValid: is_valid, value: stateValue } })
      } else {
        const check =
          /^[a-zA-ZàáâäãåæąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.0123456789'-]{2,50}$/u
        //Validates only strings with the chars in regex and from 2 to 50 characters
        let is_valid = check.test(stateValue)

        this.setState({ [stateName]: { ...this.state[stateName], isValid: is_valid, value: stateValue } })
      }
    }
  }

  validateEmail = (stateValue) => {
    const check = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    this.setState({ email: { ...this.state.email, isValid: check.test(stateValue), value: stateValue } })
  }
  validatePhone = (stateValue) => {
    if (stateValue === "") {
      this.setState({ phone: { ...this.state.phone, isValid: true, value: stateValue } })
    } else {
      const check = /^[0-9]{6,24}$/
      this.setState({ phone: { ...this.state.phone, isValid: check.test(stateValue), value: stateValue } })
    }
  }

  allValidated = () => {
    return this.state.name.isValid && this.state.phone.isValid && this.state.company.isValid && this.state.email.isValid
  }

  composeUrl = (areas, systemConfig) => {
    let shapestr = areas
      .map((coords) => {
        return coords
          .map((coord) => {
            return coord.lat() + "," + coord.lng()
          })
          .join(";")
      })
      .join("+")

    return process.env.REACT_APP_ROOT + "?shapes=" + shapestr + "&systemConfig=" + systemConfig
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({
      validated: true,
      submitting: true,
      error: false,
    })

    if (!this.allValidated()) return

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        to: process.env.REACT_APP_NOTIFY_ADDRESS,
        from: { email: "app@glintsolar.co", name: "Contact us form" },
        subject: "Customer contact",
        text: `Hello,\n\n${this.state.name.value} wants a detailed analysis.
                Here is some relevant info:
                \nAnalysis url:  ${this.composeUrl(this.props.areas, this.context.tpId)}
                \nEmail: ${this.state.email.value}
                \nCompany: ${this.state.company.value}
                \nPhone number: ${this.state.phone.value}
                \nInstalled capcity of analysis site: ${this.props.pvOut} kWh
                \nAnalysis requested from country:  ${this.context.countryName}
                \nAnalysis requested from city: ${this.context.cityName}
                \n\nYours sincerely, The Amazing App`,
        userEmail: this.state.email.value,
        userCompany: this.state.company.value,
        userPhone: this.state.phone.value,
        userName: this.state.name.value,
        shapes: this.props.areas,
        peakPowerKwh: this.props.pvOut,
        systemConfig: this.context.tpId,
        geoCountry: this.context.countryName,
        geoRegion: this.context.regionName,
        geoCity: this.context.cityName,
      }),
    }
    window.fetch(authenticate(api_endpoint + "send_mail"), requestOptions).then((resp) => {
      if (resp.ok) {
        this.resetForm()
        this.props.handleClose(true)
      } else {
        this.setState({ submitting: false, error: true })
      }
      console.log(resp.ok, resp.status)
    })
  }

  render() {
    return (
      <>
        <div className="blue-band">
          <div className="row">
            <div className="center-column">
              <h1> Get a more detailed quote.</h1>
            </div>
          </div>
          <div className="row">
            <div className="center-column">
              <p>Fill out the form below and we will be in touch, to get started on your project. </p>
            </div>
          </div>
          <Form noValidate onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="signUpColumn" style={{ width: "100%", padding: "0% 4% 0% 4%" }}>
                <Form.Row>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Full name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={this.state.name.value}
                      onChange={(e) => {
                        this.validateString("name", e.target.value)
                      }}
                      required
                      isValid={this.state.name.isValid && this.state.validated}
                      isInvalid={!this.state.name.isValid && this.state.validated}
                    />
                    <Form.Control.Feedback type="invalid">{this.state.name.feedback}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput2">
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={this.state.company.value}
                      onChange={(e) => {
                        this.validateString("company", e.target.value)
                      }}
                      isValid={this.state.company.isValid && this.state.validated}
                      isInvalid={!this.state.company.isValid && this.state.validated}
                    />
                    <Form.Control.Feedback type="invalid">{this.state.company.feedback}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="exampleForm.ControlInput3">
                    <Form.Label>Email address *</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      required
                      value={this.state.email.value}
                      onChange={(e) => {
                        this.validateEmail(e.target.value)
                      }}
                      isValid={this.state.email.isValid && this.state.validated}
                      isInvalid={!this.state.email.isValid && this.state.validated}
                    />
                    <Form.Control.Feedback type="invalid">{this.state.email.feedback}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput4">
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control
                      type="tel"
                      value={this.state.phone.value}
                      onChange={(e) => {
                        this.validatePhone(e.target.value)
                      }}
                      isValid={this.state.phone.isValid && this.state.validated}
                      isInvalid={!this.state.phone.isValid && this.state.validated}
                    />
                    <Form.Control.Feedback type="invalid">{this.state.phone.feedback}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </div>
            </div>
            <div className="row">
              <div className="center-column">
                {this.state.error && <p style={{ color: "red", background: "white" }}>Error. Please try again</p>}
                <button type="submit" className={"button"} id={"contact-us"} disabled={this.state.submitting}>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </>
    )
  }
}
