export const api_endpoint = process.env.REACT_APP_PV_API_ENDPOINT_EU1
const GLINT_FPV_API_KEY = process.env.REACT_APP_GLINT_FPV_API_KEY

export function authenticate(url) {
  if (url.indexOf("?") > -1) {
    //Check if url has query, avoiding url.includes() to be ie11 compatible
    return url + "&key=" + GLINT_FPV_API_KEY
  }
  return url + "?key=" + GLINT_FPV_API_KEY
}

export function fetchRetry(url, options = {}, retries = 5, backoff = 300, returnJson = true) {
  const retryCodes = [500, 502, 503, 504, 522, 524]
  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        if (response.status === 204) {
          return Promise.reject().catch(() => {
            console.log("No data")
          })
        } else if (returnJson) {
          return response.json()
        }
        return response
      }
      if (retries > 0 && retryCodes.includes(response.status)) {
        setTimeout(() => {
          return fetchRetry(url, options, retries - 1, backoff * 2)
        }, backoff)
      } else {
        throw new Error(response)
      }
    })
    .catch(console.error)
}

export function getPowerUnit(max_val) {
  if (max_val < Math.pow(10, 6)) {
    return { divider: 1, unit: "k" }
  } else if (max_val < Math.pow(10, 6)) {
    return { divider: Math.pow(10, 3), unit: "M" }
  } else if (max_val < Math.pow(10, 9)) {
    return { divider: Math.pow(10, 6), unit: "G" }
  } else {
    return { divider: Math.pow(10, 9), unit: "T" }
  }
}
