import React from "react"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import * as firebaseui from "firebaseui"
import "firebaseui/dist/firebaseui.css"

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})
const authUI = new firebaseui.auth.AuthUI(firebase.auth())
const firestore = firebase.firestore()

export const FirebaseContext = React.createContext(undefined)

const FirebaseProvider = (Component) => (props) =>
  (
    <FirebaseContext.Provider value={{ firebase, authUI, firestore }}>
      <Component {...props} />
    </FirebaseContext.Provider>
  )

export default FirebaseProvider
