import React, { useState } from "react"
import usePlaceAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete"
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox"
import "@reach/combobox/styles.css"

export function Search(props) {
  //makes radius dynamic so that autocomplete have no bias when map is not moved, and more after. Helps filter search.
  const [input, setInput] = useState()
  let radius = props.center.lat === 30 ? 2000000 : 500
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlaceAutocomplete({
    requestOptions: {
      location: { lat: () => props.center.lat, lng: () => props.center.lng },
      radius: radius * 1000,
    },
  })

  async function trigger(adr) {
    let address
    typeof adr === "string" ? (address = adr) : (address = input)
    setValue(address, false)
    setInput(address)
    clearSuggestions()

    try {
      const results = await getGeocode({ address })
      let latLng = await getLatLng(results[0])
      props.setLocationMarker(latLng)
      props.changeCenter(latLng, results[0].geometry.viewport)
    } catch (error) {
      console.log(error)
    }
  }
  const upDate = (e) => {
    setInput(e.target.value)
    setValue(e.target.value)
  }

  const onKeyDown = (e) => {
    if (e.key === "Enter") trigger(input)
    e.stopPropagation() // Don't interfere with other key listeners, e.g. on the map
  }

  return (
    <div className="search-container">
      <div className="search-box">
        <Combobox onSelect={trigger}>
          <ComboboxInput
            className="combobox"
            value={value}
            onChange={upDate}
            onKeyDown={onKeyDown}
            disabled={!ready}
            placeholder="Enter place or address"
          />
          <ComboboxPopover style={{ zIndex: 6000 }}>
            <ComboboxList style={{ fontFamily: "Open sans", fontSize: "16px", padding: "0", fontColor: "black" }}>
              {status === "OK" &&
                data.map(({ id, description }, idx) => <ComboboxOption key={idx} value={description} />)}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
      <button id="search-button" className="button" onClick={trigger}></button>
    </div>
  )
}
