import React from "react"

export function ButtonPanel(props) {
  return (
    <div className="button-panel">
      <button className="button" id="help-button" onClick={props.showUserGuide}>
        <div className="tooltiptext">
          <p>Show user guide</p>
        </div>
      </button>
    </div>
  )
}
