import { fetchRetry } from "../Backend"
import { getDirectionAndNumberOfUnits, range, sum } from "../utils"

function toApiAzi(azi) {
  return ((azi + 360) % 360) - 180
}

function pvgisAnnualMean(areaResponses) {
  const mean_annual_yield = sum(
    areaResponses
      .map(({ responses }) => responses.map((resp) => resp.outputs.totals.fixed.E_y / responses.length))
      .flat(2)
  )
  const weighted_mean_sd = sum(
    areaResponses.flatMap(
      ({ responses }) =>
        sum(responses.map((resp) => resp.outputs.totals.fixed.SD_y * resp.outputs.totals.fixed.E_y)) /
        sum(responses.map((resp) => resp.outputs.totals.fixed.E_y))
    )
  )
  return [mean_annual_yield, weighted_mean_sd]
}

function pvgisMonthlyMean(areaResponses) {
  const totalArea = sum(areaResponses.map(({ area }) => area))
  return range(12).map((i) => {
    return sum(
      areaResponses.flatMap(({ area, responses }) =>
        responses.map((resp) => (resp.outputs.monthly.fixed[i]["E_m"] * area) / totalArea / responses.length)
      )
    )
  })
}

function formatPvgisResponses(responses, peak_power) {
  const monthly_energy_output = pvgisMonthlyMean(responses)
  const [annual_energy_mean, annual_variability] = pvgisAnnualMean(responses)
  return {
    monthly_energy_output,
    annual_energy_mean,
    annual_variability,
    peak_power,
  }
}

async function getPvOutForArea(lat, lon, areaPolygon, config) {
  const { n, dir } = getDirectionAndNumberOfUnits(areaPolygon, config)
  const area = n * config.unit.width * config.unit.height
  const wp_per_sqm = (config.nameplate * config.unitsPerPanel) / (config.unit.width * config.unit.height)
  const peakpower = (area * wp_per_sqm) / 1000
  const pvtechchoice = config.technology
  const responses = await Promise.all(
    config.orientations.map(({ azimuth, surfaceTilt }) => {
      const params = new URLSearchParams({
        lat,
        lon,
        peakpower,
        loss: config.loss,
        pvtechchoice,
        aspect: toApiAzi(dir + azimuth),
        angle: surfaceTilt,
        usehorizon: 1,
        outputformat: "json",
      })
      return fetchRetry(`/api/pv_out?${params.toString()}`)
    })
  )
  return { area, responses }
}

export default async function getPvOut(lat, lon, areas, config) {
  const wp_per_sqm = (config.nameplate * config.unitsPerPanel) / (config.unit.width * config.unit.height)
  const responses = await Promise.all(
    areas
      .filter((area) => getDirectionAndNumberOfUnits(area, config).n > 0)
      .map((area) => getPvOutForArea(lat, lon, area, config))
  )
  const totalArea = sum(responses.map(({ area }) => area))
  const peakpower = (totalArea * wp_per_sqm) / 1000
  return formatPvgisResponses(responses, peakpower)
}
