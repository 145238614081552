import React, { useState, useEffect, Fragment, useContext } from "react"
import MapDash from "./MapDash"
import { QueryDetailsContext } from "./contexts"
import axios from "axios"
import { useLocation } from "react-router-dom"
import { FirebaseContext } from "./FirebaseProvider"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function App() {
  const [queryDetails, setQueryDetails] = useState({})
  const { firestore } = useContext(FirebaseContext)
  const [config, setConfig] = useState()
  useEffect(() => {
    firestore
      .collection("data")
      .doc(process.env.REACT_APP_CUSTOMER_ID)
      .get()
      .then((doc) => setConfig(Object.values(doc.data())[0].data))
  }, [firestore, setConfig])

  let query = useQuery()
  let tpUserName = query.get("user_name")
  let tpUserEmail = query.get("user_email")
  let tpUserId = query.get("click_id")
  let tpId = "sunbox"
  let tpName = "SunBox"
  let mode = query.get("mode") ? query.get("mode") : process.env.NODE_ENV === "development" ? "dev" : ""

  useEffect(() => {
    setQueryDetails({
      tpUserName: tpUserName,
      tpUserEmail: tpUserEmail,
      tpUserId: tpUserId,
      tpId: tpId,
      tpName: tpName,
      mode: mode,
    })

    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data
        let details = {
          countryName: data.country_name,
          regionName: data.region,
          cityName: data.city,
          tpUserName: tpUserName,
          tpUserEmail: tpUserEmail,
          tpUserId: tpUserId,
          tpId: tpId,
          tpName: tpName,
          mode: mode,
        }
        setQueryDetails(details)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [tpUserName, tpUserEmail, tpUserId, tpId, tpName, mode])
  return (
    <Fragment>
      <QueryDetailsContext.Provider value={queryDetails}>
        {config && (
          <MapDash
            //Adding shapes as a prop, not part of context,
            //to naturally allow the user to keep doing analyses for new regions.
            areas={query.get("shapes")}
            config={config}
          />
        )}
      </QueryDetailsContext.Provider>
    </Fragment>
  )
}
