import React from "react"
import { getPowerUnit } from "./Backend"
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryLabel, VictoryContainer } from "victory"

const titleFont = "Open Sans"
const monthTicks = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
const barDim = { height: 500, width: 500 }
const barPadding = { left: 100, top: 60, right: 30, bottom: 60 }

export function ProductionBarChart(props) {
  const means = props.data["monthly_energy_output"]
  const max_val = Math.max(...means)
  const { divider, unit } = getPowerUnit(max_val)
  return props.data ? (
    <VictoryChart
      containerComponent={
        <VictoryContainer
          style={{
            marginTop: "5%",
            pointerEvents: "auto",
            userSelect: "auto",
            touchAction: "auto",
          }}
        />
      }
      domainPadding={20}
      theme={VictoryTheme.material}
      padding={barPadding}
      height={barDim.height}
      width={barDim.width}
      className={"testNameSmame"}
    >
      <VictoryLabel text="Monthly production" x={105} y={35} style={{ fontFamily: titleFont, fontSize: 30 }} />

      <VictoryAxis style={{ tickLabels: { angle: -30 } }} tickFormat={monthTicks} />
      <VictoryAxis dependentAxis tickFormat={(x) => `${x / divider + " " + unit}Wh`} />
      <VictoryBar
        style={{ data: { fill: turquoise.base, width: barDim.width / 30 } }}
        data={props.data["monthly_energy_output"].map((mean, index) => ({ mean, month: index + 1 }))}
        x="month"
        y="mean"
      />
    </VictoryChart>
  ) : (
    <></>
  )
}

const turquoise = { base: "#578dc8", highlight: "#ECBE43" } //highlight used in centerlabel
